import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

import ListScreen from "./screens/ListScreen";
import FormScreen from "./screens/FormScreen";

import "./index.css";
import SignInScreen from "./screens/SignInScreen";
import UserApprovalScreen from "./screens/UserApprovalScreen";
import ChangePasswordScreen from "./screens/ChangePasswordScreen";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/userapprovals",
    element: <UserApprovalScreen />,
  },
  {
    path: "/:pageId/form",
    element: <FormScreen />,
  },
  {
    path: "/:pageId/form/:id",
    element: <FormScreen />,
  },
  {
    path: "/:pageId",
    element: <ListScreen />,
  },
  {
    path: "/",
    element: <ListScreen />,
  },
  {
    path: "/signin",
    element: <SignInScreen />,
  },
  {
    path: "/changepassword",
    element: <ChangePasswordScreen />,
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
