import React from "react";
import { TextField } from "@mui/material";

export interface LabelProps {
  id: string;
  label: string;
  value: string;
}

const Label: React.FC<LabelProps> = ({ id, label, value }) => {
  return (
    <TextField
      id={id}
      label={label}
      value={value || ""}
      variant="outlined"
      size="small"
      fullWidth
      disabled
      sx={{ mb: 3 }}
    />
  );
};

export default Label;
