import { Container } from "@mui/material";

import Layout from "../components/Layout";
import Form from "../components/Form";

import usePageMetadata from "../hooks/usePageMetadata";

const FormScreen = () => {
  const { localDB, store, form_fields, pageId, title } = usePageMetadata();

  return (
    <Layout showMenu>
      <Container component="main" sx={{ maxWidth: { xs: "sm", md: "sm" } }}>
        <Form
          pageId={pageId}
          title={title}
          localDB={localDB}
          store={store}
          fields={form_fields}
        />
      </Container>
    </Layout>
  );
};

export default FormScreen;
