import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";

export interface DropDownProps {
  id: string;
  label: string;
  value: string | null;
  options: Option[];
  helperText?: string;
  required?: boolean;
  disabled?: boolean;
  onChange(id: string, value: any | null): void;
}

export interface Option {
  label: string;
  value: string;
}

export const selectOption = { label: "-select-", value: "" };

const DropDown: React.FC<DropDownProps> = ({
  id,
  label,
  value,
  options,
  helperText,
  required,
  disabled,
  onChange,
}) => {
  return (
    <Autocomplete
      id={id}
      value={options.find((a) => a.value === value) || selectOption}
      onChange={(_, data) => onChange(id, data.value)}
      options={options}
      disabled={disabled}
      sx={{ mb: 3 }}
      disableClearable
      size="small"
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          helperText={helperText}
          required={required}
        />
      )}
    />
  );
};

export default DropDown;
