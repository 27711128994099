import { openDB } from "idb";
import { v4 as uuidv4 } from "uuid";

export interface LocalDB {
  name: string;
  version: number;
}

export const addToLocalDB = async (
  localDB: LocalDB,
  store: string,
  data: any,
  key?: string
) => {
  try {
    // Check for support.
    if (!("indexedDB" in window)) {
      console.log("This browser doesn't support IndexedDB.");
      return false;
    }

    const { name, version } = localDB;
    const db = await openDB(name, version, {
      upgrade(db) {
        db.createObjectStore(store);
      },
    });

    const id = key || uuidv4();
    db.add(store, { id: id, ...data }, id);
    db.close();
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const deleteToLocalDB = async (
  localDB: LocalDB,
  store: string,
  key: string
) => {
  // Check for support.
  if (!("indexedDB" in window)) {
    console.log("This browser doesn't support IndexedDB.");
    return;
  }

  const { name, version } = localDB;
  const db = await openDB(name, version, {
    upgrade(db) {
      db.createObjectStore(store);
    },
  });

  db.delete(store, key);
  db.close();
};

export const getDataFromLocalDB = async (localDB: LocalDB, store: string) => {
  // Check for support.
  if (!("indexedDB" in window)) {
    console.log("This browser doesn't support IndexedDB.");
    return;
  }

  const { name, version } = localDB;
  const db = await openDB(name, version, {
    upgrade(db) {
      db.createObjectStore(store);
    },
  });

  try {
    const result = await db.getAll(store);
    db.close();
    return result;
  } catch {
    return [];
  }
};

export const getDataByIdFromLocalDB = async (
  localDB: LocalDB,
  store: string,
  key: string
) => {
  // Check for support.
  if (!("indexedDB" in window)) {
    console.log("This browser doesn't support IndexedDB.");
    return;
  }

  const { name, version } = localDB;
  const db = await openDB(name, version, {
    upgrade(db) {
      db.createObjectStore(store);
    },
  });

  const result = await db.get(store, key);
  db.close();
  return result;
};
