import { Container } from "@mui/material";

import Layout from "../components/Layout";
import DataList from "../components/DataList";

import usePageMetadata from "../hooks/usePageMetadata";

const ListScreen = () => {
  const { localDB, store, list_columns, pageId, title } = usePageMetadata();

  return (
    <Layout showMenu>
      <Container
        component="main"
        sx={{
          maxWidth: { xs: "sm", md: "md", lg: "lg", xl: "xl" },
        }}
      >
        <DataList
          localDB={localDB}
          store={store}
          fields={list_columns}
          pageId={pageId}
          title={title}
        />
      </Container>
    </Layout>
  );
};

export default ListScreen;
