import React from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export interface DateTimePickerProps {
  id: string;
  label: string;
  value: string | null;
  helperText?: string;
  required?: boolean;
  disabled?: boolean;
  onChange(id: string, value: any | null): void;
}

dayjs.extend(utc);

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  id,
  label,
  value,
  helperText,
  required,
  disabled,
  onChange,
}) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      dateLibInstance={dayjs.utc}
    >
      <DatePicker
        label={label}
        value={value === undefined ? null : dayjs(value)}
        disabled={disabled}
        sx={{ mb: 3 }}
        slotProps={{
          textField: {
            id: id,
            helperText: helperText,
            required: required,
            size: "small",
            fullWidth: true,
          },
        }}
        onChange={(value) => {
          const date = dayjs(value);
          if (date.isValid()) onChange(id, date.format("YYYY-MM-DD"));
        }}
      />
    </LocalizationProvider>
  );
};

export default DateTimePicker;
