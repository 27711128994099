import { useParams } from "react-router-dom";
import metadata from "../data/site.config.json";

const usePageMetadata = () => {
  const params = useParams();
  const pageId: string =
    params.pageId === undefined ? metadata.defaultPage : params.pageId;

  const page = (metadata.pages as any)[pageId];

  return {
    pageId,
    defaultPage: metadata.defaultPage,
    localDB: metadata.localDB,
    title: page.title,
    store: page.store,
    form_fields: page.form_fields,
    list_columns: page.list_columns,
  };
};

export default usePageMetadata;
