import * as React from "react";
import AppBarMui from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { Divider, Link } from "@mui/material";

import useOnlineStatus from "../../hooks/useOnlineStatus";
import useIsRoleAdmin from "../../hooks/useIsRoleAdmin";
import useAuthorizedRoutes from "../../hooks/useAuthorizedRoutes";

export interface AppBarProps {
  title: string;
  menuItems: MenuLinkItem[];
  showMenu: boolean | undefined;
}

export interface MenuLinkItem {
  title: string;
  route: string;
}

const AppBar: React.FC<AppBarProps> = ({ title, menuItems, showMenu }) => {
  const isOnline = useOnlineStatus();
  const isRoleAdmin = useIsRoleAdmin();
  const { authorizedRoutes } = useAuthorizedRoutes();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const authorizedMenuItems = authorizedRoutes
    ? menuItems.filter((menuItem) => authorizedRoutes.includes(menuItem.route))
    : [];

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBarMui position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <AppRegistrationIcon
            sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {title}
          </Typography>

          <AppRegistrationIcon
            sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
          />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {title}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>

          {showMenu && (
            <Box sx={{ flexGrow: 0 }}>
              <IconButton
                size="large"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "block" },
                }}
              >
                {authorizedMenuItems.map((item) => (
                  <Link
                    href={isRoleAdmin ? item.route : item.route + "/form"}
                    underline="none"
                    key={item.route}
                  >
                    <MenuItem key={item.title} onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">{item.title}</Typography>
                    </MenuItem>
                  </Link>
                ))}

                {isRoleAdmin && <Divider />}
                {isRoleAdmin && (
                  <Link href="/userapprovals" underline="none">
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">User Approvals</Typography>
                    </MenuItem>
                  </Link>
                )}

                {isOnline && <Divider />}
                {isOnline && (
                  <Link href="/changepassword" underline="none">
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">
                        Change Password
                      </Typography>
                    </MenuItem>
                  </Link>
                )}
                {isOnline && <Divider />}
                {isOnline && (
                  <Link href="/signin" underline="none">
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                  </Link>
                )}
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBarMui>
  );
};

export default AppBar;
