import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  // TODO: Implement offline
  // Chip,
  Container,
  CssBaseline,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import AppBar from "../AppBar";
import Spinner from "../Spinner";

import metadata from "../../data/site.config.json";
// TODO: Implement offline
// import useOnlineStatus from "../../hooks/useOnlineStatus";
import { useApiStore } from "../../store";

export interface LayoutProps {
  showMenu?: boolean;
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ showMenu, children }) => {
  const navigate = useNavigate();
  // TODO: Implement offline
  // const isOnline = useOnlineStatus();

  const { modal, closeModal, updateOptionsLocally } = useApiStore(
    (state) => state
  );

  const menu = showMenu ? metadata.menu : [];

  useEffect(
    () => {
      // Store options locally
      if (showMenu) updateOptionsLocally(metadata.localDB);
    },
    // eslint-disable-next-line
    []
  );

  const handleClose = () => {
    if (modal.closeAction === "SignIn") navigate("/signin");
    else if (modal.closeAction === "ChangePassword")
      navigate("/changepassword");
    else if (modal.closeAction === "Reload") navigate(0);
    else if (modal.closeAction === "Back") navigate(-1);

    closeModal();
  };

  return (
    <>
      <CssBaseline />
      <AppBar title={metadata.app} menuItems={menu} showMenu={showMenu} />
      <Container
        component="main"
        sx={{
          my: 2,
          maxWidth: { xs: "sm", md: "md", lg: "lg", xl: "xl" },
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {/* TODO: Implement offline */}
        {/* {showMenu && (
          <Chip
            color={isOnline ? "success" : "error"}
            label={isOnline ? "Connected: Online" : "Connected: Offline"}
          />
        )} */}
      </Container>
      {children}

      <Spinner />
      <Dialog
        open={modal.isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{modal.title}</DialogTitle>
        <DialogContent sx={{ color: "#00000099" }}>
          {modal.content}
        </DialogContent>
        <DialogActions>
          {modal.actionButton}
          <Button onClick={handleClose} autoFocus>
            {modal.closeText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Layout;
