import usePageMetadata from "./usePageMetadata";

const useAuthorizedRoutes = () => {
  const { localDB, pageId } = usePageMetadata();
  const authorized_routes_key = `${localDB.name}_authorized_routes`;
  const authorizedRoutesString = localStorage.getItem(authorized_routes_key);
  const authorizedRoutes = authorizedRoutesString
    ? JSON.parse(authorizedRoutesString)
    : [];
  const isCurrentPageAuthorized = authorizedRoutes.includes(`/${pageId}`);

  return {
    authorizedRoutes,
    isCurrentPageAuthorized,
  };
};

export default useAuthorizedRoutes;
