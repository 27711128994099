import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import dayjs from "dayjs";

import {
  LocalDB,
  // TODO: Implement offline
  // getDataFromLocalDB,
} from "../../utils/localDB";

import { useApiStore } from "../../store";
import useAuthorizedRoutes from "../../hooks/useAuthorizedRoutes";

// TODO: Implement offline
// import useOnlineStatus from "../../hooks/useOnlineStatus";

export interface DataListProps {
  localDB: LocalDB;
  store: string;
  fields: IListColumn[];
  pageId: string;
  title: string;
}

export interface IListColumn {
  id: string;
  label: string;
  width?: number;
  format?: string;
}

const DataList: React.FC<DataListProps> = ({
  localDB,
  store,
  fields,
  pageId,
  title,
}) => {
  const { isCurrentPageAuthorized } = useAuthorizedRoutes();
  const navigate = useNavigate();

  // TODO: Implement offline
  // const isOnline = useOnlineStatus();

  const { getAllData, showModal } = useApiStore((state) => state);
  const [rows, setRows] = useState<any[]>([]);

  const fetchData = async () => {
    let records: any[] = [];
    const requisitions = await getAllData(pageId);
    if (requisitions && requisitions.length > 0) {
      requisitions.forEach((item: any) => {
        records.push({ ...item, is_local: false });
      });

      setRows(records);
    }
  };

  useEffect(
    () => {
      if (!isCurrentPageAuthorized) {
        showModal(
          "Unauthorized Access",
          "You do not have access to this page.",
          undefined,
          undefined,
          "Back"
        );

        return;
      }

      fetchData();

      // TODO: Implement offline
      // let records: any[] = [];

      // // Get data
      // getDataFromLocalDB(localDB, store).then(async (value) => {
      //   // Get data from localstorage
      //   value?.forEach((item: any) => {
      //     records.push({ ...item, is_local: true });
      //   });

      //   // Get data from database
      //   if (isOnline) {
      //     const requisitions = await getAllData(pageId);
      //     if (requisitions && requisitions.length > 0) {
      //       requisitions.forEach((item: any) => {
      //         records.push({ ...item, is_local: false });
      //       });
      //     }
      //   }

      //   setRows(records);
      // });
    },
    // eslint-disable-next-line
    [isCurrentPageAuthorized]
  );

  const columns = fields.map((field) => {
    return {
      field: field.id,
      headerName: field.label,
      width: field.width,
    } as {
      field: string;
      headerName: string;
      width: number;
      renderCell: (params: any) => React.ReactNode;
    };
  });

  // Add view button
  columns.splice(0, 0, {
    field: "actions",
    headerName: "",
    width: 100,
    renderCell: (params) => {
      return (
        <Button
          color={params.row["is_local"] ? "success" : "primary"}
          onClick={() => navigate(`/${pageId}/form/${params.row["id"]}`)}
          variant="contained"
        >
          View
        </Button>
      );
    },
  });

  // Format date values
  let data: any[] = [...rows];
  const dateColumns = fields.filter((a) => a.format !== undefined);

  data.forEach((item) => {
    dateColumns.forEach((column) => {
      const date = dayjs(item[column.id]);
      if (date.isValid()) item[column.id] = date.format(column.format);
    });
  });

  if (!isCurrentPageAuthorized) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          mt={2}
          gutterBottom
          sx={{ color: "#46505A" }}
        >
          {title}
        </Typography>
        <IconButton
          color="primary"
          aria-label="New"
          size="large"
          onClick={() => navigate(`/${pageId}/form`)}
        >
          <AddIcon fontSize="inherit" />
        </IconButton>
      </Box>

      <Box sx={{ height: 500 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          density="comfortable"
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
        />
      </Box>
    </>
  );
};

export default DataList;
