import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Paper, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import CheckBox from "../CheckBox";
import DateTimePicker from "../DateTimePicker";
import DropDown, { Option, selectOption } from "../DropDown";
import TextBox from "../TextBox";
import FileUpload from "../FileUpload";
import Label from "../Label";

import useOnlineStatus from "../../hooks/useOnlineStatus";
import useIsRoleAdmin from "../../hooks/useIsRoleAdmin";
import { useApiStore } from "../../store";
import {
  LocalDB,
  addToLocalDB,
  deleteToLocalDB,
  // TODO: Implement offline
  // getDataByIdFromLocalDB,
} from "../../utils/localDB";
import useAuthorizedRoutes from "../../hooks/useAuthorizedRoutes";

export interface FormProps {
  pageId: string;
  title: string;
  localDB: LocalDB;
  store: string;
  fields: Field[];
}

export interface Field {
  id: string;
  label: string;
  helperText: string;
  required: boolean;
  disabled: boolean;
  adminOnly?: boolean;
  editOnly?: boolean;
  component: string;
  type: string;
  rows?: number;
  option?: DropDownOption;
}

export interface DropDownOption {
  items: Option[];
}

const Form: React.FC<FormProps> = ({
  pageId,
  title,
  localDB,
  store,
  fields,
}) => {
  const { id } = useParams();
  const isOnline = useOnlineStatus();
  const isRoleAdmin = useIsRoleAdmin();
  const { isCurrentPageAuthorized } = useAuthorizedRoutes();
  const navigate = useNavigate();

  const [data, setData] = useState({} as any);
  const [files, setFiles] = useState({} as any);

  const { getOptionsLocally, closeModal, showModal, getDataById, saveData } =
    useApiStore((state) => state);

  const isEdit = id !== undefined;

  const fetchData = async () => {
    if (id) {
      // TODO: Implement offline
      // let data = (await getDataByIdFromLocalDB(localDB, store, id)) as any;

      // if (data) {
      //   console.log("local data", data);
      //   setData({ ...data });
      // } else {
      //   data = (await getDataById(pageId, id)) as any;
      //   console.log(data);
      //   if (data) {
      //     console.log("online data", data);
      //     setData({ ...data });
      //   }
      // }

      const data = (await getDataById(pageId, id)) as any;
      if (data) {
        setData({ ...data });
      }
    }
  };

  useEffect(
    () => {
      if (!isCurrentPageAuthorized) {
        showModal(
          "Unauthorized Access",
          "You do not have access to this page.",
          undefined,
          undefined,
          "Back"
        );

        return;
      }

      fetchData();
    },
    // eslint-disable-next-line
    [id, isCurrentPageAuthorized]
  );

  const handleOnChange = (id: string, value: string | null) => {
    let record = { ...data };
    record[id] = value;
    setData({ ...record });
  };

  const handleOnSubmit = () => {
    const requiredFields = fields.filter(
      (a) =>
        a.required &&
        (a.adminOnly ? isRoleAdmin : true) &&
        (isEdit ? a.editOnly : !a.editOnly)
    );
    let invalidFields: string[] = [];

    requiredFields.forEach(({ id, label }) => {
      if (data.hasOwnProperty(id)) {
        if (!data[id] || data[id] === "") invalidFields.push(label);
      } else {
        if (!files.hasOwnProperty(id) && !data[id]) invalidFields.push(label);
      }
    });

    if (invalidFields.length > 0) {
      showModal(
        "Data Validation",
        <>
          <span>The following fields are required:</span>
          <ul>
            {invalidFields.map((field) => {
              return <li key={field}>{field}</li>;
            })}
          </ul>
        </>
      );
    } else {
      showModal(
        isOnline ? "Submit Request" : "Save Request",
        "Are you sure you want to proceed?",
        "Cancel",
        <Button
          onClick={async () => {
            closeModal();
            let success = false;
            if (isOnline) {
              // Save to online database
              success = await saveData(pageId, data, files, id);

              // Delete of local copy
              if (success && id && data.is_local)
                await deleteToLocalDB(localDB, store, id);
            } else {
              // Save to local database
              success = await addToLocalDB(localDB, store, {
                ...data,
                is_local: true,
              });
            }

            if (success) {
              showModal(
                "Confirmation Success",
                "Changes submitted.",
                undefined,
                undefined,
                isRoleAdmin ? "Back" : "Reload"
              );
            }
          }}
        >
          Yes
        </Button>
      );
    }
  };

  const handleOnDeleteLocalData = () => {
    showModal(
      "Delete Local Data",
      "Are you sure you want to proceed?",
      "Cancel",
      <Button
        onClick={async () => {
          closeModal();
          if (id) await deleteToLocalDB(localDB, store, id);
          navigate(-1);
        }}
      >
        Yes
      </Button>
    );
  };

  const getOptions = (id: string, option?: DropDownOption) => {
    if (option) return [selectOption, ...option.items];

    const localOptions = getOptionsLocally(localDB, id);
    return [selectOption, ...localOptions];
  };

  if (!isCurrentPageAuthorized) {
    return null;
  }

  return (
    <Box
      sx={{
        marginTop: 3,
        marginBottom: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h4"
        component="h2"
        mt={2}
        gutterBottom
        sx={{ color: "#46505A" }}
      >
        {title}
      </Typography>

      <Paper component="form" noValidate sx={{ mt: 1, p: 4 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mb: 2,
          }}
        >
          {id && !!data.is_local && (
            <DeleteForeverIcon
              color="primary"
              fontSize="large"
              onClick={handleOnDeleteLocalData}
            />
          )}
        </Box>

        {fields
          .filter(
            (a) =>
              (isRoleAdmin || !a.adminOnly) && (isEdit ? true : !a.editOnly)
          )
          .map((field) => {
            switch (field.component) {
              case "TextBox":
                return (
                  <TextBox
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    value={data[field.id]}
                    helperText={field.helperText}
                    required={field.required}
                    disabled={field.disabled}
                    type={field.type}
                    rows={field.rows}
                    onChange={handleOnChange}
                  />
                );
              case "DropDown":
                return (
                  <DropDown
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    value={data[field.id]}
                    helperText={field.helperText}
                    options={getOptions(field.id, field.option)}
                    required={field.required}
                    disabled={field.disabled}
                    onChange={handleOnChange}
                  />
                );
              case "DatePicker":
                return (
                  <DateTimePicker
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    value={data[field.id]}
                    helperText={field.helperText}
                    required={field.required}
                    disabled={field.disabled}
                    onChange={handleOnChange}
                  />
                );
              case "CheckBox":
                return (
                  <CheckBox
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    value={data[field.id]}
                    disabled={field.disabled}
                    onChange={handleOnChange}
                  />
                );
              case "FileUpload":
                return (
                  <FileUpload
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    value={data[field.id]}
                    required={field.required}
                    getSelectedFiles={(selectedFiles: FileList) => {
                      const updatedFiled = { ...files };
                      updatedFiled[field.id] = selectedFiles;
                      setFiles({ ...updatedFiled });
                    }}
                  />
                );
              case "Label":
                return (
                  <Label
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    value={data[field.id]}
                  />
                );
              default:
                return null;
            }
          })}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
            mt: 4,
            mb: 2,
          }}
        >
          {isOnline && (
            <Button
              type="button"
              variant="contained"
              sx={{ width: { sm: 135 } }}
              onClick={handleOnSubmit}
            >
              {isRoleAdmin ? "Save" : "Submit Request"}
            </Button>
          )}

          {isRoleAdmin && (
            <Button
              type="button"
              variant="contained"
              sx={{ width: { sm: 135 } }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default Form;
