import React, { useState } from "react";
import { Box, Button, Chip, Grid, Typography } from "@mui/material";

export interface FileUploadProps {
  id: string;
  label: string;
  value: string | null;
  required?: boolean;
  disabled?: boolean;
  getSelectedFiles(files: FileList): void;
}

const FileUpload: React.FC<FileUploadProps> = ({
  id,
  label,
  value,
  required,
  getSelectedFiles,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<FileList>({} as FileList);

  return (
    <Grid sx={{ mb: 3 }}>
      <Grid item>
        <Typography variant="body2" color="#3f3d3dde">
          {label}
          {required && <span style={{ color: "red" }}>*</span>}
        </Typography>
      </Grid>
      <Grid item>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            border: "1px dashed #bdbdbd",
            py: 3,
            gap: 1,
          }}
        >
          <Button>
            <label htmlFor={id}> Choose file to upload</label>
            <input
              id={id}
              type="file"
              name={id}
              required={required}
              hidden
              multiple
              onChange={(event) => {
                const fileList = event.target.files as FileList;
                setSelectedFiles(fileList);
                getSelectedFiles(fileList);
              }}
            />
          </Button>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1,
            }}
          >
            {value &&
              (JSON.parse(value) as any[]).map((item, i) => {
                return (
                  <Chip
                    key={i}
                    label={item.name}
                    size="small"
                    variant="filled"
                  />
                );
              })}
            {selectedFiles.length
              ? Object.keys(selectedFiles).map((_, i) => {
                  return (
                    <Chip
                      key={i}
                      label={selectedFiles[i].name}
                      size="small"
                      variant="outlined"
                    />
                  );
                })
              : !value && (
                  <Typography variant="body2">No file selected.</Typography>
                )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FileUpload;
