import React from "react";
import { create } from "zustand";
import axios from "axios";

import metadata from "./data/site.config.json";
import { LocalDB } from "./utils/localDB";

const token_key = `${metadata.localDB.name}_token`;
const role_key = `${metadata.localDB.name}_role`;
const authorized_routes_key = `${metadata.localDB.name}_authorized_routes`;

const session_expired = "Session expired. You will now be redirected to login.";

interface ApiState {
  loading: boolean;
  modal: any;
  requisition: any;
  requisitions: [];
  clearLocalStorage: () => void;
  signin: (
    username: string,
    password: string
  ) => Promise<{ authenticated: boolean; isRoleAdmin?: boolean }>;
  changePassword: (
    username: string,
    oldPassword: string,
    newPassword: string
  ) => Promise<boolean>;
  showModal: (
    title: string,
    content: string | React.ReactNode,
    closeText?: string,
    actionButton?: React.ReactNode,
    closeAction?: string
  ) => void;
  closeModal: () => void;
  updateOptionsLocally: (localDB: LocalDB) => void;
  getOptionsLocally: (localDB: LocalDB, key: string) => any;
  getAllData: (pageId: string) => Promise<any>;
  getDataById: (pageId: string, id: string) => Promise<any>;
  saveData: (
    pageId: string,
    data: any,
    files: any,
    id?: string
  ) => Promise<boolean>;
  getUserApprovals: () => Promise<any>;
  saveUserApprovals: (users: any) => Promise<boolean>;
}

export const useApiStore = create<ApiState>((set) => ({
  loading: false,
  modal: { isOpen: false, title: undefined, content: undefined },
  requisition: {},
  requisitions: [],
  clearLocalStorage: () => {
    window.localStorage.removeItem(role_key);
    window.localStorage.removeItem(token_key);
    window.localStorage.removeItem(authorized_routes_key);
  },
  signin: async (username: string, password: string) => {
    set({ loading: true });
    try {
      const response = await axios({
        method: "post",
        url: metadata.endpoints.signin,
        data: { username, password },
      });

      window.localStorage.setItem(role_key, response.data.role);
      window.localStorage.setItem(token_key, response.data.token);
      window.localStorage.setItem(
        authorized_routes_key,
        JSON.stringify(metadata.menu.map((item) => item.route))
      );
      set({ loading: false });
      return {
        authenticated: true,
        isRoleAdmin: response.data.role === "Admin",
      };
    } catch (error: any) {
      set({
        loading: false,
        modal: errorModal(error),
      });
    }

    return { authenticated: false };
  },
  changePassword: async (
    username: string,
    oldPassword: string,
    newPassword: string
  ) => {
    set({ loading: true });
    try {
      const response = await axios({
        method: "post",
        url: metadata.endpoints.changePassword,
        data: { username, oldPassword, newPassword },
      });

      window.localStorage.setItem(role_key, response.data.role);
      window.localStorage.setItem(token_key, response.data.token);
      set({ loading: false });
      return true;
    } catch (error: any) {
      set({
        loading: false,
        modal: errorModal(error),
      });
    }

    return false;
  },
  showModal: (
    title: string,
    content: string | React.ReactNode,
    closeText?: string,
    actionButton?: React.ReactNode,
    closeAction?: string
  ) => {
    set((_) => ({
      modal: {
        isOpen: true,
        title: title,
        content: content,
        closeText: closeText || "OK",
        actionButton: actionButton,
        closeAction: closeAction,
      },
    }));
  },
  closeModal: () => {
    set((_) => ({
      modal: {
        isOpen: false,
        title: undefined,
        content: undefined,
        action: undefined,
        actionButton: undefined,
      },
    }));
  },
  updateOptionsLocally: async (localDB: LocalDB) => {
    set({ loading: true });

    try {
      const response = await axios({
        method: "get",
        url: metadata.endpoints.getOptions,
        headers: { "x-auth-token": window.localStorage.getItem(token_key) },
      });

      const optionKey = `${localDB.name}_options`;
      if (response.status === 200) {
        localStorage.removeItem(optionKey);
        localStorage.setItem(optionKey, JSON.stringify(response.data));
        set({ loading: false });
      }
    } catch (error: any) {
      set({
        loading: false,
        modal: errorModal(error),
      });
    }
  },
  getOptionsLocally: (localDB: LocalDB, key: string) => {
    const json = localStorage.getItem(`${localDB.name}_options`);

    if (json === null) return [];
    const obj = JSON.parse(json);
    return obj[key];
  },
  getAllData: async (pageId: string) => {
    set({ loading: true });

    try {
      const response = await axios({
        method: "get",
        url: (metadata.pages as any)[pageId].endpoints.getAllData,
        headers: { "x-auth-token": window.localStorage.getItem(token_key) },
      });

      if (response.status === 200) {
        set({ loading: false });
        return response.data;
      }
    } catch (error: any) {
      set({
        loading: false,
        modal: errorModal(error),
      });
    }
  },
  getDataById: async (pageId: string, id: string) => {
    set({ loading: true });

    try {
      const response = await axios({
        method: "get",
        url: (metadata.pages as any)[pageId].endpoints.getDataById.replace(
          "{id}",
          id
        ),
        headers: { "x-auth-token": window.localStorage.getItem(token_key) },
      });

      if (response.status === 200) {
        set({ loading: false });
        return response.data;
      }
    } catch (error: any) {
      set({
        loading: false,
        modal: errorModal(error),
      });
    }
  },
  saveData: async (pageId: string, data: any, files: any, id?: string) => {
    set({ loading: true });

    try {
      // Build the form
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }

      for (const key in files) {
        for (const file of files[key]) {
          formData.append(key, file);
        }
      }

      const method = id ? "put" : "post";
      let url = (metadata.pages as any)[pageId].endpoints.insertData;
      if (id)
        url = (metadata.pages as any)[pageId].endpoints.updateData.replace(
          "{id}",
          id
        );

      // Invoke api
      const response = await axios({
        method: method,
        url: url,
        data: formData,
        headers: {
          "x-auth-token": window.localStorage.getItem(token_key),
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        set({ loading: false });
        return true;
      }
    } catch (error: any) {
      set({
        loading: false,
        modal: errorModal(error),
      });
    }
    return false;
  },
  getUserApprovals: async () => {
    set({ loading: true });

    try {
      const response = await axios({
        method: "get",
        url: metadata.endpoints.getUserApprovals,
        headers: { "x-auth-token": window.localStorage.getItem(token_key) },
      });

      if (response.status === 200) {
        set({ loading: false });
        return response.data;
      }
    } catch (error: any) {
      set({
        loading: false,
        modal: errorModal(error),
      });
    }
  },
  saveUserApprovals: async (users: any) => {
    set({ loading: true });

    try {
      let failed: string[] = [];
      for (const user of users) {
        const response = await axios({
          method: "post",
          url: metadata.endpoints.saveUserApprovals,
          data: {
            email: user.email,
            is_admin: user.is_admin,
          },
          headers: { "x-auth-token": window.localStorage.getItem(token_key) },
        });

        if (response.status !== 200) failed.push(user);
      }

      if (failed.length > 0) {
        set({
          loading: false,
          modal: {
            isOpen: true,
            title: "User Approval",
            content: `Unabled to save the following:<br />${failed.join(
              "<br />"
            )}`,
            closeText: "OK",
          },
        });
        return false;
      }

      return true;
    } catch (error: any) {
      set({
        loading: false,
        modal: errorModal(error),
      });
      return false;
    }
  },
}));

const errorModal = (error: any) => {
  console.log(error);
  let message = JSON.stringify(error.message);
  let closeAction = undefined;

  if (error.response === undefined) {
    message =
      "Online services are down. Please contact the owner to report the issue.";
  } else {
    if (error.response.status === 401) {
      message = error.response.data.message;
      if (error.response.data === "") message = session_expired;
    } else {
      message = error.response.data.message;
    }

    if (error.response.status === 401) closeAction = "SignIn";
    else if (error.response.status === 406) closeAction = "ChangePassword";
  }

  return {
    isOpen: true,
    content: message,
    closeText: "OK",
    closeAction,
  };
};
