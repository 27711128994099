import usePageMetadata from "./usePageMetadata";

const useIsRoleAdmin = () => {
  const { localDB } = usePageMetadata();
  const role_key = `${localDB.name}_role`;
  const role = localStorage.getItem(role_key);
  return role === "Admin";
};

export default useIsRoleAdmin;
