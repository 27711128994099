import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Layout from "../components/Layout";
import { useApiStore } from "../store";

const UserApprovalScreen = () => {
  const { getUserApprovals, saveUserApprovals, showModal } = useApiStore(
    (state) => state
  );

  const [rows, setRows] = useState<any[]>([]);
  const [checkAll, setCheckAll] = useState(false);

  const fetchData = async () => {
    const data = (await getUserApprovals()) as any[];
    if (data) {
      const items = data.map((item: any) => {
        return { ...item, checked: false, is_admin: false };
      });
      setRows(items);
      console.log(items);
    }
  };

  useEffect(
    () => {
      fetchData();
    },
    // eslint-disable-next-line
    []
  );

  const handleCheckAll = () => {
    const checked = !checkAll;
    setCheckAll(checked);

    setRows([
      ...rows.map((row) => {
        return { ...row, checked };
      }),
    ]);
  };

  const handleCheck = (email: string) => {
    const row = rows.find((a) => a.email === email);
    row["checked"] = !row["checked"];
    setRows([...rows]);
  };

  const handleIsAdminChange = (email: string) => {
    const row = rows.find((a) => a.email === email);
    row["is_admin"] = !row["is_admin"];
    setRows([...rows]);
  };

  const handleSubmit = () => {
    const users = rows.filter((a) => a["checked"]);
    showModal(
      "Confirmation",
      "Are you sure you want to grant access to the selected users?",
      "Cancel",
      <Button
        type="button"
        variant="contained"
        onClick={async () => {
          const success = await saveUserApprovals(users);
          if (success) {
            showModal(
              "Confirmation Success",
              "Selected users successfully granted access.",
              undefined,
              undefined,
              "Reload"
            );
          }
        }}
      >
        Yes
      </Button>
    );
  };

  return (
    <Layout showMenu>
      <Container
        component="main"
        sx={{
          maxWidth: { xs: "sm", md: "sm" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h5"
            component="h2"
            mt={2}
            gutterBottom
            sx={{ color: "#46505A" }}
          >
            User Approvals
          </Typography>
        </Box>
        <Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={checkAll}
                      onChange={handleCheckAll}
                    />
                  </TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell sx={{ width: 100 }} align="center">
                    Is Admin
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, id) => {
                  return (
                    <TableRow key={id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={row["checked"]}
                          onChange={() => handleCheck(row["email"])}
                        />
                      </TableCell>
                      <TableCell>{row["email"]}</TableCell>
                      <TableCell padding="checkbox" align="center">
                        <Select
                          size="small"
                          value={!!row["is_admin"] ? "Yes" : "No"}
                          onChange={() => handleIsAdminChange(row["email"])}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ display: "flex", mt: 2, justifyContent: "flex-end" }}>
          <Button
            type="button"
            variant="contained"
            sx={{ width: 150 }}
            disabled={rows.filter((a) => a.checked).length === 0}
            onClick={() => handleSubmit()}
          >
            Grant Access
          </Button>
        </Box>
      </Container>
    </Layout>
  );
};

export default UserApprovalScreen;
