import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import Layout from "../components/Layout";
import { useApiStore } from "../store";

const ChangePasswordScreen = () => {
  const navigate = useNavigate();

  const { clearLocalStorage, showModal, changePassword } = useApiStore(
    (state) => state
  );

  useEffect(
    () => {
      clearLocalStorage();
    },
    // eslint-disable-next-line
    []
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email")?.toString() || "";
    const oldPassword = data.get("oldPassword")?.toString() || "";
    const newPassword = data.get("newPassword")?.toString() || "";
    const confirmPassword = data.get("confirmPassword")?.toString() || "";

    if (newPassword !== confirmPassword) {
      showModal("Validation", "Passwords do not match.");
      return;
    }
    const success = await changePassword(email, oldPassword, newPassword);
    if (success) navigate(`/signin`);
  };

  return (
    <Layout>
      <Container component="main" sx={{ maxWidth: { xs: "sm", md: "sm" } }}>
        <Paper
          sx={{
            marginTop: 8,
            padding: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOpenIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Change Password
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="oldPassword"
              label="Old Password"
              type="password"
              id="oldPassword"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="New Password"
              type="password"
              id="newPassword"
              autoComplete="off"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm New Password"
              type="password"
              id="confirmPassword"
              autoComplete="off"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              size="large"
            >
              Proceed
            </Button>
          </Box>
        </Paper>
      </Container>
    </Layout>
  );
};

export default ChangePasswordScreen;
