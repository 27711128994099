import React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export interface CheckBoxProps {
  id: string;
  label: string;
  value: string;
  disabled?: boolean;
  onChange(id: string, value: any | null): void;
}

const CheckBox: React.FC<CheckBoxProps> = ({
  id,
  label,
  value,
  disabled,
  onChange,
}) => {
  return (
    <FormGroup sx={{ height: "30px", mb: 3 }}>
      <FormControlLabel
        label={label}
        checked={!value || value === "0" ? false : true}
        disabled={disabled}
        control={<Checkbox id={id} />}
        onChange={(e: any) => onChange(id, e.target.checked ? "1" : "0")}
      />
    </FormGroup>
  );
};

export default CheckBox;
