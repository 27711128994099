import React from "react";
import { TextField } from "@mui/material";

export interface TextBoxProps {
  id: string;
  label: string;
  type?: string;
  value: string | null;
  placeholder?: string;
  helperText?: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  rows?: number;
  onChange(id: string, value: any | null): void;
}

const TextBox: React.FC<TextBoxProps> = ({
  id,
  label,
  type = "text",
  value,
  placeholder,
  helperText,
  required,
  disabled,
  error,
  rows,
  onChange,
  ...otherProps
}) => {
  return (
    <TextField
      {...otherProps}
      id={id}
      label={label}
      type={type}
      value={value || ""}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      helperText={helperText}
      variant="outlined"
      size="small"
      fullWidth
      sx={{ mb: 3 }}
      multiline={!!rows ? true : undefined}
      rows={rows}
      onChange={(e) => onChange(id, e.target.value)}
    />
  );
};

export default TextBox;
